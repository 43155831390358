<template>
  <div class="invest-wrapper mx-auto">
    <OverlayInvestOrderButton ref="overlayOrder" />

    <v-row no-gutters>
      <v-col cols="12" class="mb-3">
        <AISupportBox />
      </v-col>

      <template v-if="tab === 'investing'">
        <v-card class="elevation-0 rounded--8 mb-3">
          <v-card-title class="font-weight-bold">
            <img src="@/assets/images/funding/woori-crowd-4.png" height="25" alt="wc2" class="mb-1" />
          </v-card-title>
          <v-card-subtitle class="color--gray-3 font-size--13">
            정부가 펀다와 함께 성장 가능성이 높은 소상공인들을 선별하고, 동네주민에게 지역 상점에 투자 할 수 있는 상생형 크라우드펀딩 상품입니다.
          </v-card-subtitle>
        </v-card>
      </template>
    </v-row>

    <section class="section__invest-head">
      <v-row no-gutters>
        <v-col cols="12" class="tab-wrapper">
          <v-btn
            class="tab__btn mr-2"
            :class="{ active: tab === 'investing' }"
            @click="switchFundings('investing')"
            text
          >
            투자가능<span class="font-size--13"
              >({{ investingFundings.length }})</span
            >
          </v-btn>

          <v-btn
            class="tab__btn ml-2"
            :class="{ active: tab === 'selected' }"
            @click="switchFundings('selected')"
            text
          >
            선택상점<span class="font-size--13"
              >({{ selectedFundings.length }})</span
            >
          </v-btn>
        </v-col>
      </v-row>

      <v-row
        class="invest-head__sort"
        :class="{ 'margin-b-15': hasAlreadyInvested }"
        no-gutters
      >
        <v-col v-if="false" cols="12">
          <p class="tab__title">
            <button
              class="btn__sort btn-funda-basic"
              @click="sortFundings('interestRate')"
              :class="{
                selected: sorting[tab].key === 'interestRate',
                desc: sorting[tab].desc,
              }"
              :disabled="sorting.isLoading"
            >
              <LoadingText :is-loading="sorting.isLoading">
                수익률순
              </LoadingText>
            </button>

            <button
              class="btn__sort btn-funda-basic"
              @click="sortFundings('useDays')"
              :class="{
                selected: sorting[tab].key === 'useDays',
                desc: sorting[tab].desc,
              }"
              :disabled="sorting.isLoading"
            >
              <LoadingText :is-loading="sorting.isLoading">
                기간순
              </LoadingText>
            </button>
          </p>
        </v-col>

        <v-col
          v-if="tab === 'investing' && hasAlreadyInvested === true"
          cols="12"
          class="mb-0 ml-1"
        >
          <div class="d-flex align-center ma-0">
            <input
              id="check__already"
              name="check__already"
              type="checkbox"
              class="sort__checkbox outline-color--funda-blue"
              :class="{ checked: excludeAlreadyInvested }"
              @click="clickExcludeAlready()"
            />
            <label for="check__already" class="cursor--pointer font-size--13">
              <v-icon class="icon__already" small>
                mdi-flag-variant
              </v-icon>
              내 투자금이 남아 있는 상점 제외하기
            </label>
          </div>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row no-gutters>
        <v-col cols="12">
          <template v-if="fundingsLoading">
            <div class="loading">
              <MyLoader />
            </div>
          </template>

          <template v-else>
            <template v-if="fundings.length < 1">
              <div class="no-data">
                <p
                  class="ma-0 color--gray font-size--15 text-center"
                  style="width: 80%; line-height: 1.3"
                >
                  <template v-if="tab === 'selected'">
                    선택 상점이 없습니다
                  </template>
                  <template v-else>
                    <p class="my-2 line-height--20">
                      투자 상품을 준비중입니다 <br /><br />

                      <template v-if="activeTab.name === 'CARD_SALES'">
                        새로운 투자 상점은 매일 10~16시 실시간 업데이트 됩니다
                      </template>
                    </p>
                  </template>
                </p>
              </div>
            </template>

            <FundingCard
              v-for="(funding, index) in fundings.slice(
                0,
                paging.offset + paging.size
              )"
              :funding="funding"
              :key="`${funding.fundingDataIdx}-${funding.title}`"
              :showXai="index === 0"
            />

            <div class="pt-3 px-5">
              <v-btn
                v-if="tab === 'investing'"
                v-show="investingFundings.length > 0"
                class="btn__more"
                depressed
                tile
                outlined
                :disabled="
                  moreLoading ||
                    investingFundings.length < paging.offset + paging.size
                "
                :loading="moreLoading"
                @click="getMoreFundings()"
              >
                {{
                  investingFundings.length &lt; paging.offset + paging.size
                    ? '-'
                    : '투자 상품 더 보기'
                }}
              </v-btn>
            </div>
          </template>
        </v-col>
      </v-row>
    </section>

    <div
      v-show="fundings.length > 0 && hideFloating === false"
      class="order-receipt"
    >
      <div class="order-receipt__contents">
        <v-row no-gutters>
          <v-col cols="12" :class="showReceiptDetails ? 'd-none' : 'd-flex justify-center mb-2'">
            <v-btn
              class="btn__divide mr-2"
              :class="{ active: selectedDivide === 0 }"
              text
              @click="doDivide(0)"
              >#예치금완전분산
            </v-btn>
            <v-menu offset-y top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="btn__divide"
                  :class="{
                    active:
                      Number.isInteger(selectedDivide) && selectedDivide > 0,
                  }"
                  text
                  >#<template
                    v-if="
                      Number.isInteger(selectedDivide) && selectedDivide > 0
                    "
                    >{{ divideOption[selectedDivide].text }}씩
                  </template>
                  <template v-else>
                    <span class="font-weight-bold overdue-status__2">
                      N
                    </span>
                    원씩
                  </template>
                  일괄투자
                </v-btn>
              </template>
              <v-list min-width="120px" max-height="220px">
                <div
                  v-for="(item, index) in divideOption"
                  v-show="index > 0"
                  :key="index"
                  style="background-color: white"
                  class="v-list-item v-list-item--link theme--light"
                  :class="{
                    'v-item--active v-list-item--active':
                      index === selectedDivide,
                  }"
                  @click="doDivide(index)"
                >
                  <div class="v-list-item__content">
                    <template v-if="index === divideOption.length - 1">
                      직접입력
                    </template>
                    <template v-else>
                      {{ item.text }}
                    </template>
                  </div>
                </div>
              </v-list>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            class="order-receipt__details"
            :class="{ close: !showReceiptDetails }"
          >
            <v-row class="mb-1" no-gutters>
              <v-col cols="6" class="order-receipt__item">
                <p>내 예치금:</p>
              </v-col>
              <v-col cols="6" class="order-receipt__amount">
                <template v-if="isAuthenticated">
                  <p><BalanceText />원</p>
                </template>
                <template v-else>
                  <a @click="showLogin()">로그인</a>
                </template>
              </v-col>
            </v-row>

            <v-row class="mb-1" no-gutters>
              <v-col cols="5" class="order-receipt__item">
                <p>투자 예정 금액:</p>
              </v-col>
              <v-col cols="7" class="order-receipt__amount">
                <p>{{ checkedAmount | commaFormat }} 원</p>
                <div v-if="isAuthenticated && notEnoughDeposit > 0">
                  <p class="pa-0 color--pink">
                    {{ notEnoughDeposit | commaFormat }}원 부족
                    <a
                      class="font-size--14 text-decoration--underline"
                      @click="showAccount = !showAccount"
                    >
                      입금하기
                    </a>
                  </p>
                </div>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="5" class="order-receipt__item">
                <p>투자 상품:</p>
              </v-col>
              <v-col cols="7" class="order-receipt__amount">
                <p>{{ checkedFundings.length | commaFormat }} 개</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="3" class="pr-1">
            <v-btn
              color="#333333"
              class="order-receipt__btn white--text updown"
              @click="showReceiptDetails = !showReceiptDetails"
            >
              {{ showReceiptDetails ? '내리기' : '투자내역' }}
            </v-btn>
          </v-col>
          <v-col cols="9" class="pl-1">
            <v-btn
              color="#1baaf1"
              class="order-receipt__btn"
              @click="clickFloatingBtn()"
            >
              <template v-if="notEnoughDeposit > 0">
                {{ isAuthenticated ? '예치금이 부족합니다' : '투자하기' }}
              </template>
              <template v-else> 투자하기 </template>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-dialog v-model="selfDivide.showDialog" max-width="300px" hide-overlay>
      <v-card v-if="isAuthenticated && !!balance" class="modal__my-account">
        <v-card-title class="pt-2 px-2 pb-0 justify-end">
          <v-btn
            width="24px"
            height="24px"
            icon
            color="white"
            @click="selfDivide.showDialog = false"
          >
            <v-icon class="font-size--20">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="px-3 pb-5">
          <p class="margin-b-15 color--white font-size--16 text-center">
            금액을 입력해주세요
          </p>

          <v-row no-gutters>
            <v-col cols="12" class="text-center">
              <div class="position--relative mb-3 text-center">
                <input
                  class="self-divide__input"
                  v-input-currency="selfDivide.value"
                  placeholder="0"
                  type="tel"
                  :max="balance.withdraw"
                />
                <span class="self-divide__won"> 원 </span>
              </div>
              <button class="self-divide__btn" @click="clickSelfDivide()">
                분배
              </button>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showAccount" max-width="500px" hide-overlay>
      <v-card class="modal__my-account">
        <v-card-title class="pt-2 px-2 pb-0 justify-end">
          <v-btn
            width="24px"
            height="24px"
            icon
            color="white"
            @click="showAccount = false"
          >
            <v-icon class="font-size--20">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text v-if="!!userAccount" class="px-3 pb-3">
          <p class="d-flex align-start mb-2 font-size--17 color--white">
            잔액:
            <span class="mx-2"> <BalanceText />원 </span>
            <v-btn
              width="17px"
              height="17px"
              icon
              color="green"
              :class="{ 'refresh-balance': refreshBalanceLoading }"
              :disabled="refreshBalanceLoading"
              :loading="refreshBalanceLoading"
              @click="refreshBalance()"
            >
              <v-icon class="font-size--19">mdi-refresh</v-icon>
            </v-btn>
          </p>

          <p class="mb-1 font-size--16 color--white">
            예금주: {{ userAccount.accountHolder }}
          </p>
          <p class="mb-1 font-size--16 color--white">
            은행명: {{ userAccount.bank }}
          </p>
          <p class="mb-1 font-size--16 color--white">
            계좌번호: {{ userAccount.account }}

            <button
              class="btn-funda rounded"
              @click="copyText(userAccount.account)"
              style="padding: 1px 4px 0"
            >
              <span>복사</span>
            </button>
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <ChannelPluginStyle
      key="isInvestStyle"
      :stylePack="{
        'is-invest': 'right: 10px !important; bottom: 7px !important;',
      }"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import { InvestmentTypes } from '@/store/types/investment'
import { TimeTypes } from '@/store/types/time'
import { textCopy, shuffle } from '@/util'

export default {
  components: {
    MyLoader: () => import('@/components/MyLoader.vue'),
    LoadingText: () => import('@/components/common/text/LoadingText.vue'),
    BalanceText: () => import('@/components/common/text/BalanceText.vue'),
    OverlayInvestOrderButton: () => import('@/components/overlay/button/OverlayInvestOrderButton.vue'),
    AISupportBox: () => import('@/components/invest/AISupportBox.vue'),
    FundingCard: () => import('@/components/fundings/FundingCard.vue'),
    ChannelPluginStyle: () =>
      import('@/components/channel/ChannelPluginStyle.vue'),
  },
  data() {
    return {
      MAX_INVEST_RATE: 0.05,
      tabTypes: [
        {
          name: 'CARD_SALES',
          title: '매출담보',
          isActive: false,
          values: ['FUNDANOW', 'MARKETING_LOAN', 'EARLY_PAYMENT'],
          shouldShowProgressBar: false,
        },
        {
          name: 'SECOND_MORTGAGE',
          title: '아파트담보',
          isActive: false,
          values: ['SECOND_MORTGAGE'],
          shouldShowProgressBar: true,
        },
      ],
      tab: 'investing',
      hideFloating: false,
      paging: {
        offset: 0,
        size: 20,
      },
      fundingsLoading: false,
      moreLoading: false,
      excludeAlreadyInvested: false,
      rawData: [],
      fundings: [],
      sorting: {
        isLoading: false,
        investing: {
          key: null,
          desc: null,
        },
        selected: {
          key: null,
          desc: null,
        },
      },
      clickFunding: null,
      showReceiptDetails: false,
      selectedDivide: null,
      selfDivide: {
        showDialog: false,
        value: null,
      },
      divideOption: [
        {
          text: '골고루',
          value: -999,
        },
        {
          text: '5천원',
          value: 5000,
        },
        {
          text: '1만원',
          value: 10000,
        },
        {
          text: '3만원',
          value: 30000,
        },
        {
          text: '5만원',
          value: 50000,
        },
        {
          text: '10만원',
          value: 100000,
        },
        {
          text: '직접입력',
          value: null,
        },
      ],
      showAccount: false,
      refreshBalanceLoading: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      isAuthenticated: AuthTypes.getters.IS_AUTHENTICATED,
      userIdx: AuthTypes.getters.GET_USER_IDX,
      profile: AuthTypes.getters.GET_PROFILE,
      balance: AuthTypes.getters.GET_BALANCE,
      balanceLoading: AuthTypes.getters.GET_BALANCE_LOADING,
      userValidation: AuthTypes.getters.GET_USER_VALIDATION,
    }),
    ...mapState('funding', {
      announcements: 'announcements',
      storeInvestFundings: 'investFundings',
      investFundingsRawDatas: 'investFundingsRawDatas'
    }),
    isValidUser() {
      return this.userValidation?.isValid === true
    },
    userAccount() {
      return this.profile?.newBankAccountFunda
    },
    activeTab() {
      const tab = this.tabTypes.filter((v) => {
        return v.isActive === true
      })
      return tab[0] || { name: null, values: [] }
    },
    hasAlreadyInvested() {
      return this.rawData.some((f) => f.alreadyInvested === true)
    },
    investingFundings() {
      // console.log('investingFundings', this.rawData)
      return this.rawData.filter((f) => {
        return this.inCondition(f)
      })
    },
    selectedFundings() {
      let selectedFundings = this.fundings.filter((f) => {
        return f.investAmount > 0
      })
      return selectedFundings
    },
    checkedFundings() {
      let checkedFundings = this.selectedFundings.filter((f) => {
        return f.isErr === false
      })
      checkedFundings.sort((a, b) => b.investAmount - a.investAmount)
      return checkedFundings
    },
    checkedAmount() {
      let amt = 0
      this.checkedFundings.forEach((f) => {
        amt += parseInt(f.investAmount, 10)
      })
      return amt
    },
    estimatedPoint() {
      let p = 0
      if (this.balanceLoading === false && !!this.balance) {
        const myPoint = this.balance.point
        p = this.checkedAmount * 0.5
        p = Math.min(myPoint, p)
      } else {
        p = -1
      }
      return p
    },
    notEnoughDeposit() {
      if (this.estimatedPoint >= 0) {
        const d =
          this.checkedAmount - this.estimatedPoint - this.balance?.withdraw
        return Math.max(d, 0)
      }
      return -1
    },
  },
  watch: {
    userIdx: {
      async handler(nv, ov) {
        const isSignin = (!!nv && !ov)
        const isSignout = (!nv && !!ov)
        if (isSignin) {
          // this.getBalance()
          this.$router.push({ name: 'invest' })
        }

        if (isSignout === false) {
          this.getInvestingFundings()
        }
      },
    },
    activeTab: {
      deep: true,
      handler() {
        this.doDivide(null)
        this.switchFundings('investing')
      },
    },
    excludeAlreadyInvested() {
      this.switchFundings('investing')
    },
    checkedAmount(v) {
      if (v === 0) {
        this.showReceiptDetails = false
      }
    },
    selectedDivide(v) {
      this.rawData.forEach((f) => {
        f.investAmount = 0
        f.point = 0
      })

      if (Number.isInteger(v)) {
        const perAmount = this.divideOption[v].value
        const isDivideFull = perAmount === -999

        if (isDivideFull) {
          this.divideFull(this.investingFundings, this.balance.withdraw)
        } else {
          let count = parseInt(this.balance.withdraw / perAmount)

          for (let index = 0; index < count; index++) {
            if (!!!this.investingFundings[index]) {
              break
            }

            if (this.isSkipDivide(this.investingFundings[index])) {
              count++
              continue
            }

            if (this.investingFundings[index].max < perAmount) {
              count++
              continue
            }

            this.investingFundings[index].investAmount = perAmount
          }
        }

        this.fundings = this.investingFundings

        if (this.selectedFundings.length > 0) {
          this.switchFundings('selected')
        } else {
          this.doDivide(null)
          this.$swal.basic.warning(
            '예치금이 부족하거나 분배가능 상점이 없습니다'
          )
        }
      } else {
        this.switchFundings('investing')
      }
    },
    rawData: {
      deep: true,
      async handler(fundings) {
        this.setInvestFundingsRawDatas(fundings)
      }
    },
    fundings: {
      deep: true,
      handler(v) {
        this.setInvestFundings(v)
      }
    }
  },
  created() {
    _seo({
      titles: ['투자하기'],
      url: '/invest',
    })

    this.cannotTime()

    this.$eventBus.$on('complete-invest', () => {
      this.completeInvest()
    })

    this.getInvestingFundings()
  },
  methods: {
    ...mapActions('auth', {
      showLogin: AuthTypes.actions.ON_LOGIN_DIALOG,
      getBalance: AuthTypes.actions.GET_BALANCE,
    }),
    ...mapActions('investment', {
      getUserP2p: InvestmentTypes.actions.GET_USER_P2P,
    }),
    ...mapActions('time', {
      getIsInspectTime: TimeTypes.actions.GET_CANNOT_INVEST_TIME,
    }),
    ...mapActions('funding', ['getFundingAnnouncements']),
    ...mapMutations('funding', ['setInvestFundings', 'setInvestFundingsRawDatas']),
    inCondition(funding) {
      const values = this.activeTab?.values
      const inValues = values.includes(funding.fundanowType)

      if (this.excludeAlreadyInvested) {
        return funding.alreadyInvested !== true && inValues
      }

      return inValues
    },
    shouldShowProgressBar() {
      return this.activeTab?.shouldShowProgressBar
    },
    investmentProgress(funding) {
      const max = 100
      const start = 25
      return start + (max - start) * funding.amtRate
    },
    selectTab(tab) {
      if (tab === null) {
        this.tabTypes.forEach((v) => {
          v.isActive = false
        })
        return
      }

      this.tabTypes.forEach((v) => {
        if (v.name === tab.name) {
          v.isActive = true
        } else {
          v.isActive = false
        }
      })
    },
    isMarketingLoan(funding) {
      return funding.fundanowType === 'MARKETING_LOAN'
    },
    isSkipDivide(funding) {
      return funding.isOpen === false
    },
    async clickExcludeAlready() {
      if (this.checkedAmount > 0) {
        const yes = await this.$swal.basic.confirm(
          '입력된 금액이 초기화됩니다. 계속 진행하시겠습니까?'
        )
        if (yes.isConfirmed === false) {
          return
        }
      }

      this.doDivide(null)
      this.excludeAlreadyInvested = !this.excludeAlreadyInvested
    },
    async cannotTime() {
      const cannot = await this.getIsInspectTime()
      this.hideFloating = cannot

      if (cannot) {
        const txt = `
        <span class="color-funda--basic font-size--18" style="font-weight: bold;">
          투자 제한 시간 안내
        </span>
        <br />
        <br />
        ㅁ 제한시간: 
        <br />
        매일 23시 20분 ~
        <br /> 04시 까지

        <hr class="hr-solid width-100 margin-y-15" />

        <div style="display: flex; justify-content: center;">
          <ul class="font-size--15" style="text-align: left;">
            <li class="margin-b-5">
              금융결제원 서버 점검
            </li>
            <li>
              투자자 상환 진행
            </li>
          </ul>
        </div>
        `

        this.$swal.basic.warning({ html: txt })
      }

      return cannot
    },
    copyText(txt) {
      try {
        textCopy(txt)
        this.$swal.basic.alert('복사되었습니다.')
      } catch (err) {
        this.$swal.basic.error('다시 시도해주세요.')
      }
    },
    async refreshBalance() {
      try {
        this.refreshBalanceLoading = true
        await this.getBalance()
      } finally {
        this.refreshBalanceLoading = false
      }
    },
    floorUnitAmount(v, fundingUnit) {
      if (Number.isInteger(v)) {
        let result = parseInt(v / fundingUnit) // 몫
        result = result * fundingUnit
        return result
      }

      return 0
    },
    switchFundings(v) {
      this.tab = v
      if (v === 'investing') {
        this.fundings = this.investingFundings
      } else if (v === 'selected') {
        this.showReceiptDetails = true
        this.sorting.selected.key = null
        this.fundings = this.selectedFundings
      }
    },
    sortFundings(key) {
      this.sorting.isLoading = true
      this.sorting[this.tab].key = key
      this.sorting[this.tab].desc = !this.sorting[this.tab].desc

      this.fundings.sort((a, b) => {
        if (this.sorting[this.tab].desc === false) {
          return a[key] - b[key]
        } else {
          return b[key] - a[key]
        }
      })

      this.sorting.isLoading = false
    },
    doDivide(divideIdx) {
      if (divideIdx === null) {
        this.selectedDivide = null
        return
      }

      if (this.isAuthenticated === false) {
        this.showLogin()
        return
      }

      if (this.investingFundings?.length < 1) {
        return
      }

      if (divideIdx === this.divideOption.length - 1) {
        if (!!this.selectedDivide) {
          this.doDivide(null)
        } else {
          this.selfDivide.showDialog = true
        }
        return
      }

      const value = this.selectedDivide === divideIdx ? null : divideIdx
      this.selectedDivide = value
    },
    clickSelfDivide() {
      let dValue = parseInt(this.selfDivide.value, 10)

      if (dValue < 5000) {
        this.$swal.basic.alert('최소금액은 5,000원 입니다')
        return
      }

      const selfIndex = this.divideOption.length - 1
      const dUnit = dValue / 10000 < 1 ? 1000 : 10000
      dValue = this.floorUnitAmount(dValue, 1000)

      let wonText = dUnit === 1000 ? '천원' : '만원'
      // eslint-disable-next-line
      this.divideOption[selfIndex].text = `${dValue / dUnit}${wonText}`
      this.divideOption[selfIndex].value = dValue

      this.selectedDivide = selfIndex
      this.selfDivide.value = 0
      this.selfDivide.showDialog = false
    },
    divideFull(fundings, withdraw) {
      // 최소단위금액
      const MIN_UNIT_AMOUNT = 1000

      // eslint-disable-next-line
      if (Array.isArray(fundings) === false || Number.isInteger(withdraw) === false) {
        return
      }

      if (fundings.length < 1) {
        return
      }

      if (withdraw < MIN_UNIT_AMOUNT) {
        return
      }

      const count = fundings.length
      let perAmt = parseInt(withdraw / count)
      let tempArr = []

      if (perAmt < MIN_UNIT_AMOUNT) {
        perAmt = MIN_UNIT_AMOUNT
      }

      for (const f of fundings) {
        const alreadyAmt = f.investAmount === null ? 0 : f.investAmount
        const max = f.max - alreadyAmt

        if (this.isSkipDivide(f)) {
          continue
        }

        // 투자가능한 최대 금액이 단위금액보다 작으면 넘김
        if (max < f.unitInvestAmount) {
          continue
        }

        // 기투자 금액이 최소투자금액보다 작으면 최소투자금액으로 초기화
        if (alreadyAmt < f.minInvestAmount) {
          perAmt = f.minInvestAmount
        }

        const temp = Math.min(perAmt, max)
        if (withdraw < temp) {
          continue
        }

        const inv = this.floorUnitAmount(temp, f.unitInvestAmount)
        if (inv < 1) {
          continue
        }

        const sumInv = inv + alreadyAmt
        if (sumInv < f.minInvestAmount) {
          continue
        }

        f.investAmount = sumInv
        withdraw -= inv
        tempArr.push(f)
      }

      if (tempArr.length > 0 && withdraw > 0) {
        this.divideFull(tempArr, withdraw)
      }
    },
    getCurInvestRate(cur, total) {
      let v = cur / total
      v = parseFloat(v.toFixed(4))
      return v
    },
    async fetchData() {
      if (this.storeInvestFundings.length > 0) {
        this.rawData = this.investFundingsRawDatas
        this.fundings = this.storeInvestFundings
        this.selectTab(null)
        this.selectTab(this.tabTypes[0])
      }

      if (this.selectedFundings.length > 0) {
        return
      }
      const q = `
        data: getInvestFundingDatas(inputInvestFundingDatas: {
          offset: ${this.paging.offset}
          size: ${this.paging.size}
          type: ""
          ${
            !!this.userIdx ? 'ignoreListCreditorUserIdx: ' + this.userIdx : ''
          }
        }) {
          fundingDataIdx
          title
          subTitle
          visible
          totalAmount
          announcedAmount
          interestRate
          loanPeriod
          disclosureDate
          investmentStartDate
          investmentClosureDate
          minInvestAmount
          maxInvestAmount
          unitInvestAmount
          status
          useDays
          currentInvestmentAmount
          alreadyInvested
          averageMonthlySales
          forecastedMonthlySales
          fundanowType

          isOpen
        }
      `

      try {
        const [data] = await Promise.all([this.$fundaApi.query(gql`{${q}}`), this.getUserP2p()])
        let underTenArr = []
        let overTenArr = []
        for (const funding of data) {
          let floorUnitAmount = funding.announcedAmount * this.MAX_INVEST_RATE
          if (funding.fundanowType === 'EARLY_PAYMENT') {
            floorUnitAmount = Math.floor(funding.announcedAmount * this.MAX_INVEST_RATE)
            if (funding.unitInvestAmount === 1) {
              funding.unitInvestAmount = 1000
              funding.minInvestAmount = 5000
            }
          }

          let maxInvestmentAmount = this.floorUnitAmount(
            floorUnitAmount,
            funding.unitInvestAmount
          )
          let remaining = funding.announcedAmount - funding.currentInvestmentAmount
          if (funding.fundanowType === 'EARLY_PAYMENT') {
            // 둘다 1000원단위로 맞춤
            maxInvestmentAmount = Math.floor(maxInvestmentAmount / 1000) * 1000
            remaining = Math.floor(remaining / 1000) * 1000
          }

          remaining = Math.min(remaining, maxInvestmentAmount)
          if (remaining >= 5000) {
            const amtRate = this.getCurInvestRate(
              funding.currentInvestmentAmount,
              funding.announcedAmount
            )

            funding.amtRate = amtRate
            funding.investAmount = null
            funding.storeBalanceLoading = false
            funding.investmentBalance = null
            funding.max = remaining
            funding.isErr = false
            // funding.isOpen = true
            funding.errTxt = `상품에 투자 가능한 금액: ${this.$options.filters.commaFormat(
              remaining
            )} 원`

            if (this.isMarketingLoan(funding)) {
              if (amtRate < 0.6) {
                underTenArr.push(funding)
              } else {
                overTenArr.push(funding)
              }
              continue
            }

            if (amtRate < 0.1) {
              underTenArr.push(funding)
            } else {
              overTenArr.push(funding)
            }
          }
        }

        underTenArr = shuffle(underTenArr)
        overTenArr = shuffle(overTenArr)

        const rawData = [...underTenArr, ...overTenArr]
        const result = await this.getFundingAnnouncements(rawData.map(f => f.fundingDataIdx))
        rawData.forEach(f => {
          const find = result.find(r => r.fundingDataIdx === f.fundingDataIdx)
          f.disclosure = JSON.parse(find?.disclosureJson)
        })

        this.rawData = rawData

        this.selectTab(null)
        this.selectTab(this.tabTypes[0])
      } catch (e) {
        throw new Error(e)
      }
    },
    async getInvestingFundings() {
      if (this.fundingsLoading === true) {
        return
      }

      this.fundingsLoading = true
      this.paging.offset = 0

      try {
        await this.fetchData()
      } finally {
        this.fundingsLoading = false
      }
    },
    async getMoreFundings() {
      if (
        this.investingFundings.length <
        this.paging.offset + this.paging.size
      ) {
        return
      }
      this.moreLoading = true

      setTimeout(() => {
        this.paging.offset += this.paging.size
        this.moreLoading = false
      }, 300)
    },
    async clickFloatingBtn() {
      if (this.isAuthenticated === false) {
        this.showLogin()
        return
      }

      if (this.isValidUser !== true) {
        this.$swal.basic.alert('본인인증을 완료해주세요')
        return
      }

      if (await this.cannotTime()) {
        return
      }

      if (this.checkedAmount < 1) {
        this.$swal.basic.alert('투자 금액을 입력해주세요')
        return
      }

      if (this.notEnoughDeposit > 0) {
        return
      }

      this.$refs.overlayOrder.openDialog(this.checkedFundings)
    },
    async completeInvest() {
      this.selectTab(null)
      await this.getInvestingFundings()
    },
  },
}
</script>

<style lang="scss" scoped>
.invest-wrapper {
  max-width: 768px;
  width: 95%;
  padding: 12px 0;
}

.section {
  &__tab {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    background-color: white;
    box-shadow: 0 8px 16px 0 rgba(23, 25, 26, 0.1);
  }

  &__invest-head {
    background-color: transparent;
  }
}

.invest-head__sort {
  margin-top: 15px;
}

.tab__type {
  max-width: 768px;
  height: 60px;
  font-size: 17px;
  list-style: none;

  display: flex;
  justify-content: center;

  margin: 0 auto;
  padding: 0;

  li {
    width: 120px;
    cursor: pointer;
    padding: 8px 0 4px;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      border-bottom: 2px solid #1baaf1;
      color: #1baaf1;
      font-weight: 900;
    }
  }

  @media screen and (max-width: 768px) {
    height: 55px;
  }
}

.tab-wrapper {
  width: 100%;
  left: 0;
  border-bottom: 1px solid #E5E7EC;
  text-align: center;
}

.tab__btn {
  position: relative;
  bottom: -1px;
  max-width: 150px;
  width: 40%;

  border-bottom: 1px solid;
  border-color: #ADB3BE !important;
  border-radius: 0px;
  color: #ADB3BE;

  font-size: 15px;
  font-weight: 900;
  line-height: 1;
  word-spacing: 1px;

  padding: 20px 10px !important;
}
.tab__btn.active {
  border-color: black !important;
  color: black !important;
}
.tab__title {
  margin: 10px 0 0;
  padding: 0 0 4px;
  color: #3f3f3f;
  font-size: 15px;
  font-weight: bold;
  text-align: right;
}

.v-tooltip__content {
  margin-top: -5px;
}

.btn__sort {
  position: relative;
  min-width: 70px;
  margin-left: 4px;
  padding: 4px 18px 4px 8px;
  border: 1px solid #1baaf1;
  border-radius: 12px;
  font-size: 13px;
}

.btn__sort:before,
.btn__sort:after {
  border: 4px solid transparent;
  content: '';
  display: block;
  height: 0;
  position: absolute;
  width: 0;
  top: 11px;
  right: 7px;
}
.btn__sort:before {
  border-bottom-color: #1baaf1;
  margin-top: -9px;
}
.btn__sort:after {
  border-top-color: #1baaf1;
  margin-top: 1px;
}
.btn__sort.selected:not(.desc):before {
  border-bottom-color: white;
  margin-top: -9px;
}
.btn__sort.selected.desc:after {
  border-top-color: white;
  margin-top: 1px;
}

.loading,
.no-data {
  display: flex;
  min-height: 330px;
  align-items: center;
  justify-content: center;
}

input[type='checkbox'].sort__checkbox {
  width: 19px !important;
  height: 19px;
  margin: 0;
  padding: 0 !important;
  border: 1px solid rgb(38, 139, 239, 0.3);
  border-radius: 100%;
  background: url('~@/assets/images/common/icons/etc-safe-checkbox.svg')
    transparent no-repeat;
  background-size: contain;
  opacity: 1 !important;
  visibility: visible !important;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
input[type='checkbox'].sort__checkbox.checked {
  background: url('~@/assets/images/common/icons/etc-checkbox-selected.svg')
    transparent no-repeat;
  background-size: contain;
}

.icon__already {
  margin-left: 3px;
  color: #268bef;
  transform: rotate(15deg);
}

.btn__more {
  width: 100%;
}

.order-receipt {
  position: fixed;
  left: 0;
  width: 100%;
  bottom: 0;
  background-color: rgb(244, 247, 247, 0.8);
  border-top-right-radius: 6px;
  box-shadow: 0px 10px 17px 0px rgb(0, 0, 0, 0.7);
  padding: 8px 10px;

  &__up-down {
    position: absolute;
    top: -31px;
    left: 0;
    max-width: 100px;
    width: 100%;
    background-color: white;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    box-shadow: 2px -1px 7px 1px rgb(0, 0, 0, 0.15);
    text-align: center;

    @media screen and (max-width: 768px) {
      max-width: 70px;
    }

    > i {
      color: #1baaf1;
      font-size: 31px !important;
    }
  }

  &__contents {
    max-width: 768px;
    margin: 0 auto;
  }

  &__details {
    max-height: 150px;
    background-color: #e5f3fd;
    border: #e7edf6;
    border-radius: 6px;
    margin-bottom: 6px;
    padding: 8px 12px !important;

    transition: all 0.4s ease;

    &.close {
      max-height: 0px;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      margin: 0;
      opacity: 0;
    }
  }

  &__item {
    p {
      margin: 0;
      padding-top: 4px;
      padding-bottom: 4px;
      font-size: 14px;
      font-weight: bold;
    }
  }

  &__amount {
    text-align: right;
    p {
      margin: 0;
      padding-top: 4px;
      padding-bottom: 4px;
      font-size: 14px;
      font-weight: bold;
      text-align: right;
    }
  }

  &__btn {
    width: 100%;
    height: 60px !important;
    color: white;
    font-size: 15px;
    font-weight: bold;

    @media screen and (max-width: 768px) {
      height: 42px !important;

      &:not(.updown) {
        width: calc(100% - 50px);
      }
    }
  }
}

.btn__divide {
  height: unset !important;
  padding: 3px 7px !important;
  background-color: white !important;
  border: 1px solid #1baaf1;
  border-radius: 12px;
  color: #1baaf1 !important;
  font-size: 14px;

  &.active {
    background-color: #1baaf1 !important;
    color: white !important;
  }

  &::v-deep span {
    line-height: 1.3;
  }
}

.modal__my-account {
  background-color: rgb(0, 0, 0, 0.8) !important;

  .refresh-balance {
    color: #4caf50 !important;
    caret-color: #4caf50 !important;
  }
}

.self-divide {
  &__input {
    width: 80%;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
    font-size: 18px;
    border: 1px solid white;
    text-align: center;

    &::placeholder {
      color: white;
      font-size: 18px;
    }

    &:focus {
      outline: none;
      border: none;
      border-bottom: 2px solid #1baaf1;
    }
  }

  &__won {
    position: absolute;
    top: 11px;
    right: 50px;
    color: white;
    font-size: 18px;
  }

  &__btn {
    background-color: #1baaf1;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
    color: white;
  }
}

.swal2-icon.icon--smaill {
  min-width: 17px;
  width: 17px;
  height: 17px;
  margin: 0 !important;
  border-width: 2px;

  &.swal2-success {
    .swal2-success-line-tip {
      width: 5px !important;
      height: 2px !important;
      left: 3px !important;
      top: 9px !important;
      transform: rotate(38deg);
    }

    .swal2-success-line-long {
      top: 8px !important;
      left: 5px !important;
      height: 2px !important;
      width: 10px !important;
    }
  }

  &.swal2-warning {
    border-color: #f9356a !important;
    color: #f9356a !important;

    .swal2-icon-content {
      font-size: 14px !important;
    }
  }
}
.investment-progress-bar {
  margin-top: 20px;
}
</style>
